import * as React from "react";
import * as styles from "./OffsetCard.module.scss";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { getImage } from "../contentful/data-provider";

const OffsetCard = ({ data, selectChange }) => {
	return (
		<div className={styles.container}>
				<div className={styles.imageContainer}>
					<img className={styles.image} src={getImage(data.image).url} alt={getImage(data.image).title} />
				</div>
				<div className={styles.textContainer}>
					<h2 className={styles.title}>{data.title}</h2>
					<div className={styles.description}>{renderRichText(data.body)}</div>
				</div>
		</div>
	);
};

export default OffsetCard;
