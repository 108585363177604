import * as React from 'react';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import PageTitle from '../../components/PageTitle';
import Section from '../../components/Section';
import OffsetCardGrid from '../../components/OffsetCardGrid';
import {graphql} from 'gatsby';

const Pharmaceuticals = ({data}) => {
    const {contentfulBasicPageSection, contentfulProductDetailsSection} = data;

    return (
        <Layout>
            <main>
                <PageHeader>
                    <PageTitle>
                        <div>{contentfulBasicPageSection.subtitle}</div>
                        <h1>{contentfulBasicPageSection.title}</h1>
                    </PageTitle>
                </PageHeader>
                <Section hasMaxWidth hasNoDesktopBottomPadding>
                    <OffsetCardGrid cards={contentfulProductDetailsSection.elements} body={contentfulBasicPageSection.body}/>
                </Section>
            </main>
        </Layout>
    )
}

export default Pharmaceuticals;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
  query ($locale: String!) {
    contentfulBasicPageSection(
      contentful_id: {eq: "5YZYk3OHiTn5sZQRo1MqcM"}
      node_locale: {eq: $locale}
    ) {
      ... BasicPageFields
    }
    contentfulProductDetailsSection(contentful_id: {eq: "5kdnifXLW54gSbgKjEV4eN"}, node_locale: {eq: $locale}) {
      ... ProductDetailsFields
    }
  }
`;
