import * as React from 'react';
import * as styles from './OffsetCardGrid.module.scss';
import OffsetCard from './OffsetCard';
import {BLOCKS} from "@contentful/rich-text-types";
import {renderRichText} from "gatsby-source-contentful/rich-text";

const OffsetCardGrid = ({cards, body, children}) => {
    const renderCards = () => {
        return cards && cards.map((card, index) => {
            return <OffsetCard key={index} data={card} className={styles.container}/>
        });
    };

    const options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <div className={styles.textHighlight}>
                        {children}
                    </div>
                )
            },
            [BLOCKS.PARAGRAPH]: (node, children) => {
                return (
                    <div className={styles.textNormal}>
                        {children}
                    </div>
                )
            },
        },
    };

    return (
        <div className={styles.container}>
            <div className={styles.textContainer}>
                {renderRichText(body, options)}
            </div>
            {children}
            {renderCards()}
        </div>
    )
};

export default OffsetCardGrid;